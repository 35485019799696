import request from "@/core/services/axios";
export function gradelist(params) {
  // 获取年级列表
  return request({
    url: `/cms/schoolgradeclass/gradelist`,
    method: "get",
    params: params,
  });
}
export function getClassNum(params) {
  // 获取班级列表
  return request({
    url: `/cms/schoolteacher/getClassNum`,
    method: "get",
    params: params,
  });
}
export function getStudentNewExam(params) {
  // 学生信息获取（新高考）
  return request({
    url: `/cms/schoolstudent/page?current=${params.current}&size=${params.size}`,
    method: "post",
    data: params,
  });
}
export function delStudentNewExam(params) {
  // 删除学生信息获取
  return request({
    url: `/cms/schoolstudent/${params.id}`,
    method: "delete",
    data: params,
  });
}
export function addStudent(params) {
  // 学生信息保存（新高考）
  return request({
    url: `/cms/schoolstudent`,
    method: "post",
    data: params,
  });
}
export function exportAnswerSheet(params) {
  // 导出学生答题卡
  return request({
    url: `/cms/schoolstudent/exportAnswerSheet`,
    method: "post",
    data: params,
  });
}
export function editStudent(params) {
  // 学生信息修改（新高考）
  return request({
    url: `/cms/schoolstudent`,
    method: "put",
    data: params,
  });
}
export function exportstudent(params) {
  // 导出学生
  return request({
    url: `/cms/schoolstudent/exportstudent`,
    method: "get",
    params: params,
  });
}
export function cleanStudent(params) {
  // 学生信息 清除数据
  return request({
    url: `/cms/schoolstudent/clean`,
    method: "post",
    data: params,
  });
}
export function classlistbylevelandyear(params) {
  // 通过学段和入学年份查询班级列表
  return request({
    url: `/cms/schoolgradeclass/classlistbylevelandyear`,
    method: "get",
    params: params,
  });
}
export function removeStudent(params) {
  // 通过id批量删除学生
  return request({
    url: `/cms/schoolstudent/remove`,
    method: "post",
    data: params,
  });
}
export function batchSearchStudent(params) {
  //上传文件批量搜索学生
  return request({
    url: `/cms/schoolstudent/importsearch`,
    method: "post",
    data: params,
  });
}
export function getClasslist(params) {
  // 查询班级列表
  return request({
    url: `/cms/schoolgradeclass/classlist`,
    method: "get",
    params: params,
  });
}
export function schoolteacher(params) {
  // 查询老师
  return request({
    url: `/cms/schoolteacher/page`,
    method: "get",
    params: params,
  });
}
export function addSchoolteacher(params) {
  // 新增教师
  return request({
    url: `/cms/schoolteacher`,
    method: "post",
    data: params,
  });
}
export function editSchoolteacher(params) {
  // 编辑教师
  return request({
    url: `/cms/schoolteacher`,
    method: "put",
    data: params,
  });
}
export function delSchoolteacher(params) {
  // 通过id删除老师
  return request({
    url: `/cms/schoolteacher/${params.id}`,
    method: "delete",
  });
}
export function removeTeacher(params) {
  // 批量删除教师信息
  return request({
    url: `/cms/schoolteacher/remove`,
    method: "post",
    data: params,
  });
}
export function cleanTeacher(params) {
  // 清空老师
  return request({
    url: `/cms/schoolteacher/clean`,
    method: "post",
    params: params,
  });
}
export function exportteacher(params) {
  // 导出老师
  return request({
    url: `/cms/schoolteacher/exportteacher`,
    method: "get",
    params: params,
  });
}
export function exportteacherUser(params) {
  // 导出用户
  return request({
    url: `/cms/schoolteacher/exportteacher/user`,
    method: "get",
    params: params,
  });
}

export function getParams(params) {
  // 获取参数设置
  return request({
    url: `/cms/school/param`,
    method: "get",
    params: params,
  });
}
export function setParams(params) {
  // 设置参数
  return request({
    url: `/cms/school/param`,
    method: "post",
    data: params,
  });
}
export function addschoolgradeclass(params) {
  // 班级管理-班级信息添加
  return request({
    url: `/cms/schoolgradeclass`,
    method: "post",
    data: params,
  });
}
export function getClassNumPage(params) {
  // 班级管理-班级列表获取
  return request({
    url: `/cms/schoolgradeclass/page?current=${params.current}&size=${params.size}`,
    method: "post",
    data: params,
  });
}
export function classlevel() {
  // 班级管理-班级层次列表获取
  return request({
    url: `/cms/schoolgradeclass/classlevel/list`,
    method: "get",
  });
}

// 所有科目获取
export function schoolAllSubject() {
  return request({
    url: `/cms/school/all/subject`,
    method: "get",
  });
}

// 本校科目获取
export function schoolSubject() {
  return request({
    url: `/cms/school/subject`,
    method: "get",
  });
}

// 保存学校设置的科目
export function postSubject(params) {
  return request({
    url: `/cms/school/subject`,
    method: "post",
    data: params,
  });
}

// 新增科目
export function addsubject(params) {
  return request({
    url: `/cms/school/add/subject`,
    method: "post",
    data: params,
  });
}

// 导出阅卷进度
export function exportRate(params) {
  return request({
    url: `/exam/monitor/export/all/rate`,
    method: "get",
    params: params,
  });
}

export function saveClasslevel(params) {
  // 班级管理-班级层次保存
  return request({
    url: `/cms/schoolgradeclass/classlevel`,
    method: "post",
    data: params,
  });
}
export function editSchoolgradeclass(params) {
  // 班级管理-班级层次保存
  return request({
    url: `/cms/schoolgradeclass`,
    method: "put",
    data: params,
  });
}
export function teacherImport(params) {
  // 老师导入
  return request({
    url: `/cms/schoolteacher/import`,
    method: "post",
    data: params,
  });
}
export function getSchoolTeacher({ gradeId }) {
  // 获取学校老师列表
  return request({
    url: `/cms/schoolteacher/teacher/list`,
    method: "get",
    params: {
      isSet: 1,
      gradeId: gradeId,
    },
  });
}
export function getExamDivisonParams(params) {
  // new学校参数获取
  return request({
    url: `/cms/school/new/param`,
    method: "get",
    params: params,
  });
}
export function setExamDivisonParams(params) {
  // new学校参数设置
  return request({
    url: `/cms/school/new/param`,
    method: "post",
    data: params,
  });
}
export function getSubject() {
  // 获取学校科目
  return request({
    url: `/cms/school/subject`,
    method: "get",
  });
}
export function getTrackPage(params) {
  // 教师当前监视生分页查询
  return request({
    url: `/cms/trackstudent/page`,
    method: "post",
    data: params,
  });
}
export function addpage(params) {
  // 教师添加跟踪生分页查询
  return request({
    url: `/cms/trackstudent/addpage`,
    method: "post",
    data: params,
  });
}
export function addtrackstudent(params) {
  // 教师添加跟踪生分页查询
  return request({
    url: `/cms/trackstudent/addtrackstudent`,
    method: "GET",
    params: params,
  });
}
export function deletetrackstudent(params) {
  // 删除跟踪生
  return request({
    url: `/cms/trackstudent/deletetrackstudent`,
    method: "GET",
    params: params,
  });
}
export function schoolstatparampage(params) {
  // 统计参数分页查询
  return request({
    url: `/cms/school/schoolstatparampage`,
    method: "post",
    data: params,
  });
}
export function deleteschoolstatparam(params) {
  // 删除统计参数
  return request({
    url: `/cms/school/deleteschoolstatparam`,
    method: "get",
    params: params,
  });
}
export function changeschoolstatparamisdefault(params) {
  // 修改统计参数是否为默认
  return request({
    url: `/cms/school/changeschoolstatparamisdefault`,
    method: "get",
    params: params,
  });
}
export function saveschoolstatparam(params) {
  // 改统计参数
  return request({
    url: `/cms/school/saveschoolstatparam`,
    method: "post",
    data: params,
  });
}
export function insertschoolstatparam(params) {
  // 新增统计参数
  return request({
    url: `/cms/school/insertschoolstatparam`,
    method: "post",
    data: params,
  });
}
export function resetExamwork(params) {
  // 新增统计参数
  return request({
    url: `/cms/school/reset/examwork`,
    method: "put",
    params: params,
  });
}
export function deletelist(params) {
  // -删除考试列表
  return request({
    url: `/exam/exam/deletelist`,
    method: "get",
    params: params,
  });
}
export function reduction(params) {
  // 恢复考试项目
  return request({
    url: `/exam/exam/reduction`,
    method: "post",
    data: params,
  });
}
export function deleteAgain(params) {
  // 彻底删除考试项目
  return request({
    url: `/exam/exam/deleteAgain`,
    method: "post",
    data: params,
  });
}
export function getschoolrecognitionsetting(params) {
  // 获取批改痕迹设置
  return request({
    url: `/cms/school/getschoolrecognitionsetting`,
    method: "get",
    params: params,
  });
}

export function setschoolrecognitionsetting(params) {
  // 获取批改痕迹设置
  return request({
    url: `/cms/school/setschoolrecognitionsetting`,
    method: "post",
    data: params,
  });
}
