export const academic = [
  {
    path: "/academic/statistics",
    name: "academic-statistics",
    meta: {
      tagName: "academic",
      name: "academic-statistics",
      tabName: "academic",
      newViews: true,
      title: "统计设置",
    },
    // 统计设置
    component: () => import("@/views/academic/statistics"),
  },
  {
    path: "/academic/statistics/merge",
    name: "academic-statistics-merge",
    meta: {
      newViews: true,
      tagName: "academic",
      title: "合并科目",
    },
    // 合并科目
    component: () => import("@/views/academic/merge"),
  },
  {
    path: "/academic/statistics/split",
    name: "academic-statistics-split",
    meta: {
      newViews: true,
      tagName: "academic",
      title: "拆分科目",
    },
    // 拆分科目
    component: () => import("@/views/academic/split"),
  },
  {
    path: "/academic/statistics/splitSubject",
    name: "academic-statistics-splitSubject",
    meta: {
      newViews: true,
      tagName: "academic",
      title: "拆分科目",
    },
    // 拆分科目
    component: () => import("@/views/academic/splitSubject"),
  },
  {
    path: "/academic/statistics/staticsSetting",
    name: "academic-statistics-staticsSetting",
    meta: {
      tagName: "academic",
      title: "统计参数设置",
    },
    // 统计参数设置
    component: () => import("@/views/academic/staticsSetting"),
  },
  {
    path: "/academic/statistics/publish",
    name: "academic-statistics-publish",
    meta: {
      title: "发布成绩给学生",
      tagName: "academic",
    },
    // 发布成绩给学生
    component: () => import("@/views/academic/publish"),
  },
  {
    path: "/academic/statistics/createStat",
    name: "academic-statistics-createStat",
    meta: {
      newViews: true,
      tagName: "academic",
      title: "生成统计",
    },
    //生成统计
    component: () => import("@/views/academic/createStat"),
  },
  {
    path: "/academic/statistics/setting",
    name: "academic-statistics-setting",
    meta: {
      newViews: true,
      tagName: "academic",
      title: "统计设置",
    },
    redirect: (to) => {
      return {
        path: "/academic/statistics/setting/settingWay",
        query: {
          ...to.query,
        },
      };
    },
    //生成统计
    component: () => import("@/views/academic/setting/index"),
    children: [
      {
        path: "/academic/statistics/setting/settingWay",
        name: "academic-statistics-setting-settingWay",
        meta: {
          newViews: true,
          tagName: "academic",
          title: "统计设置",
        },
        component: () => import("@/views/academic/components/statTypeSetting"),
      },
      {
        path: "/academic/statistics/setting/params",
        name: "academic-statistics-setting-params",
        meta: {
          newViews: true,
          tagName: "academic",
          title: "统计参数",
        },
        component: () =>
          import(
            "@/views/academic/components/statParamsSetting/statParamsSetting.vue"
          ),
      },
      {
        path: "/academic/statistics/setting/questions",
        name: "academic-statistics-setting-questions",
        meta: {
          newViews: true,
          tagName: "academic",
          title: "大题设置",
        },
        component: () => import("@/views/academic/unionPage/questions.vue"),
      },
      {
        path: "/academic/statistics/setting/ap",
        name: "academic-statistics-setting-ap",
        meta: {
          newViews: true,
          tagName: "academic",
          title: "赋分设置",
        },
        component: () => import("@/views/academic/components/Ap/index.vue"),
      },
      {
        path: "/academic/statistics/setting/areaSetting",
        name: "academic-statistics-setting-areaSetting",
        meta: {
          newViews: true,
          tagName: "academic",
          title: "基础统计设置/区域统计设置",
        },
        component: () => import("@/views/academic/unionPage/areaSetting.vue"),
      },
    ],
  },
  {
    path: "/academic/reportStat/",
    name: "academic-reportStat",
    meta: {
      newViews: true,
      tabName: "academic",
      name: "/academic/reportStat",
      title: "统计报告",
      heightLight: true,
    },
    //统计报告
    component: () => import("@/views/academic/reportStat"),
  },

  {
    path: "/academic/reportStat/reportDetail",
    name: "/academic/reportStat/reportDetail",
    meta: {
      newViews: true,
      name: "/academic/reportStat",
      tagName: "academic",
      title: "查看报告",
    },
    //查看报告
    component: () => import("@/views/academic/reportDetail"),
  },
  {
    path: "/academic/union/publish",
    name: "/academic/union/publish",
    meta: {
      newViews: true,
      name: "/academic/reportStat",
      tagName: "academic",
      title: "发布成绩",
    },
    //发布成绩
    component: () => import("@/views/union/publish/index"),
  },

  {
    path: "/academic/reportStat/schoolReport",
    name: "/academic/reportStat/schoolReport",
    meta: {
      newViews: true,
      tagName: "academic",

      tabName: "academic",
      name: "/academic/reportStat",
      title: "校级报告",
    },
    //校级报告
    component: () => import("@/views/academic/schoolReport/index"),
  },
  {
    path: "/academic/reportStat/teachingReport",
    name: "/academic/reportStat/teachingReport",
    meta: {
      newViews: true,
      tabName: "academic",
      tagName: "academic",
      name: "/academic/reportStat",
      title: "教研报告",
    },
    //教研报告
    component: () => import("@/views/academic/teachingReport/index"),
  },
  {
    path: "/academic/reportStat/headReport",
    name: "/academic/reportStat/headReport",
    meta: {
      newViews: true,
      tagName: "academic",
      name: "/academic/reportStat",
      tabName: "academic",
      title: "班主任报告",
    },
    //班主任报告
    component: () => import("@/views/academic/headReport/index"),
  },
  {
    path: "/academic/reportStat/teacherReport",
    name: "/academic/reportStat/teacherReport",
    meta: {
      newViews: true,
      tagName: "academic",
      name: "/academic/reportStat",
      tabName: "academic",
      title: "任课教师报告",
    },
    //任课教师报告
    component: () => import("@/views/academic/teacherReport/index"),
  },
  {
    path: "/academic/reportStat/paperReport",
    name: "/academic/reportStat/paperReport",
    meta: {
      newViews: true,
      tagName: "academic",
      tabName: "academic",
      name: "/academic/reportStat",
      title: "试卷讲评",
    },
    //试卷讲评
    component: () => import("@/views/academic/paperReport/index"),
  },
  {
    path: "/academic/reportStat/unionReport",
    name: "/academic/reportStat/unionReport",
    meta: {
      newViews: true,
      tagName: "academic",
      tabName: "academic",
      name: "/academic/reportStat",
      title: "联考报告",
    },
    //联考报告
    component: () => import("@/views/union/academic/report/index"),
  },
  {
    path: "/academic/reportStat/student",
    name: "/academic/reportStat/student",
    meta: {
      newViews: true,
      tagName: "academic",
      tabName: "academic",
      name: "/academic/reportStat",
      title: "学生榜单",
    },
    //学生榜单
    component: () => import("@/views/academic/studentReport/index"),
  },
  {
    path: "/academic/reportStat/studentReport",
    name: "/academic/reportStat/studentReport",
    meta: {
      newViews: true,
      tagName: "academic",
      name: "/academic/reportStat",
      tabName: "academic",
      title: "学生报告",
    },
    //学生报告
    component: () => import("@/views/academic/studentReport/studentReport"),
  },
  {
    path: "/academic/reportStat/newExamStat",
    name: "/academic/reportStat/newExamStat",
    meta: {
      newViews: true,
      tagName: "academic",
      name: "/academic/reportStat",
      tabName: "academic",
      title: "新高考基础统计",
    },
    //新高考基础统计
    component: () => import("@/views/academic/newExamStat/index"),
  },
  {
    path: "/academic/once",
    name: "/academic/once",
    meta: {
      newViews: true,
      tagName: "academic",
      tabName: "academic",
      name: "/academic/reportStat",
      title: "一次考试统计",
    },
    //一次考试统计
    component: () => import("@/views/academic/once/index"),
  },

  {
    path: "/academic/once/classTopTotal",
    name: "/academic/once/classTopTotal",
    meta: {
      newViews: true,
      tagName: "academic",
      tabName: "academic",
      name: "/academic/reportStat",
      title: "一次考试统计详情",
    },
    //一次考试统计详情
    component: () => import("@/views/academic/once/classTopTotal.vue"),
  },

  {
    path: "/academic/card",
    name: "/academic/card",
    meta: {
      newViews: true,
      tagName: "academic",
      tabName: "academic",
      name: "/academic/reportStat",
      title: "查看答题卡",
    },
    //
    component: () => import("@/views/academic/card.vue"),
  },
  {
    path: "/academic/once/setting",
    name: "/academic/once/setting",
    meta: {
      newViews: true,
      tagName: "academic",
      tabName: "academic",
      name: "/academic/reportStat",
      title: "导出试卷",
    },
    //
    component: () => import("@/views/academic/once/setting.vue"),
  },
  {
    path: "/academic/once/NewExam/GradeRank",
    name: "/academic/once/NewExam/GradeRank",
    meta: {
      newViews: true,
      tagName: "academic",
      tabName: "academic",
      name: "/academic/reportStat",
      title: "年级榜",
    },
    // 新高考年级榜
    component: () => import("@/views/academic/once/NewExam/GradeRank.vue"),
  },
  {
    path: "/academic/once/NewExam/ClassRank",
    name: "/academic/once/NewExam/ClassRank",
    meta: {
      newViews: true,
      tagName: "academic",
      tabName: "academic",
      name: "/academic/reportStat",
      title: "班级榜",
    },
    // 新高考班级榜
    component: () => import("@/views/academic/once/NewExam/ClassRank.vue"),
  },
  {
    path: "/academic/once/NewExam/TargetCompare",
    name: "/academic/once/NewExam/TargetCompare",
    meta: {
      newViews: true,
      tagName: "academic",
      tabName: "academic",
      name: "/academic/reportStat",
      title: "各项指标对比",
    },
    // 新高各项指标对比
    component: () => import("@/views/academic/once/NewExam/TargetCompare.vue"),
  },
  {
    path: "/academic/once/NewExam/ScoreCompare",
    name: "/academic/once/NewExam/ScoreCompare",
    meta: {
      newViews: true,
      tagName: "academic",
      tabName: "academic",
      name: "/academic/reportStat",
      title: "分数段对比",
    },
    // 新高分数段对比
    component: () => import("@/views/academic/once/NewExam/ScoreCompare.vue"),
  },
  {
    path: "/academic/new-exam/union-report",
    name: "/academic/new-exam/union-report",
    meta: {
      newViews: true,
      tagName: "academic",
      tabName: "academic",
      name: "/academic/reportStat",
      title: "新高考-联考报告",
    },
    // 新高联考报告
    component: () => import("@/views/newExamAcademic/union/index.vue"),
  },
  {
    path: "/academic/new-exam/school-report",
    name: "/academic/new-exam/school-report",
    meta: {
      newViews: true,
      tagName: "academic",
      tabName: "academic",
      name: "/academic/reportStat",
      title: "新高考-校级报告",
    },
    // 新高校级报告
    component: () => import("@/views/newExamAcademic/school/index.vue"),
  },
  {
    path: "/academic/new-exam/teaching-report",
    name: "/academic/new-exam/teaching-report",
    meta: {
      newViews: true,
      tagName: "academic",
      tabName: "academic",
      name: "/academic/reportStat",
      title: "新高考-教研报告",
    },
    // 新高教研报告
    component: () => import("@/views/newExamAcademic/teaching/index.vue"),
  },
  {
    path: "/academic/new-exam/director-report",
    name: "/academic/new-exam/director-report",
    meta: {
      newViews: true,
      tagName: "academic",
      tabName: "academic",
      name: "/academic/reportStat",
      title: "新高考-班主任报告",
    },
    // 新高班主任报告
    component: () => import("@/views/newExamAcademic/director/index.vue"),
  },
  {
    path: "/academic/new-exam/teacher-report",
    name: "/academic/new-exam/teacher-report",
    meta: {
      newViews: true,
      tagName: "academic",
      tabName: "academic",
      name: "/academic/reportStat",
      title: "新高考-任课老师报告",
    },
    // 新高任课老师报告
    component: () => import("@/views/newExamAcademic/teacher/index.vue"),
  },
  {
    path: "/academic/new-exam/student-report",
    name: "/academic/new-exam/student-report",
    meta: {
      newViews: true,
      tagName: "academic",
      tabName: "academic",
      name: "/academic/reportStat",
      title: "新高考-学生报告",
    },
    // 新高任课老师报告
    component: () => import("@/views/newExamAcademic/student/index.vue"),
  },
  {
    path: "/academic/new-exam/student-rank",
    name: "/academic/new-exam/student-rank",
    meta: {
      newViews: true,
      tagName: "academic",
      tabName: "academic",
      name: "/academic/reportStat",
      title: "新高考-学生榜单",
    },
    //新高任学生榜单
    component: () => import("@/views/newExamAcademic/student/rank/index.vue"),
  },
];
